@import '../../styles/variables';

.Header {
  position: fixed;
  z-index: 11;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: $headerHeight;
  width: 100vw;
  background-color: $redShareDarkBlue;
  box-shadow: 0 5px $redShareBlue;

  .branding {
    margin: 0;
    padding-left: 17px;
    display: flex;
    align-items: center;

    .link {
      text-decoration: none !important;
      display: block;
      margin-left: 10px;

      svg {
        margin-top: -7px;
      }

      .tagline {
        color: $white;
        font-size: 10px;
        font-weight: normal;
        text-align: center;
        margin: -13px 0 0 3px;
      }
    }
  }
}
