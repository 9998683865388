// ... import other override files from here.
// Override files should be named by what they're overriding.
// ant-modal overrides should go into ./_ant-modal.scss, for instance.
// NOTE: ant-modal should include any styles of components that are also obligate children of a parent.
// example: _ant-modal.scss should include all of .ant-modal, .ant-modal-header, .ant-modal-body, etc.

.REDshare {
  // ... any @imports of overrides go here.
  // Because it's inside of the .REDshare selector,
  // all imported selectors will be prefixed with the above one
  // as an ancestor selector.
  // example: importing an override that defines ".ant-modal {...}"
  //   will produce a final output of ".REDshare .ant-modal {...}"
  // @import "some-file";
  @import 'button-styles';
}
